






































































































































































































































































import { Component, Vue, Model, Watch } from 'vue-property-decorator'
import CalendarService from '@/services/CalendarService'
import { triggerFavorite } from '@/helpers/GetNotificationCount'
import HeaderCompact from '@/components/HeaderCompact.vue'
import NavMenuBar from '@/components/NavMenuBar.vue'
import Footer from '@/components/Footer.vue'
import moment from 'moment'
import linkifyHtml from "linkify-html";
import {
  EDeadLineType,
  EPlanType,
  ERepeatType,
  EShareType,
  EUserStatus,
  IdName,
  IdNameStatus
} from '@/models'

@Component({
  components: {
    HeaderCompact,
    NavMenuBar,
    Footer
  }
})
export default class EventPreview extends Vue {
  @Model('change', { type: String, default: '' }) updateView!: string
  private eventPreview: EventPreviewVM = new EventPreviewVM()
  private current_user_id: number = 0
  private display_title: string = ''
  private errorMess: string = ''
  private modalAction: any = () => {}
  private eDeleteRepeat: any = EDeleteRepeat
  private ePlanType: any = EPlanType
  private deleteRepeatType: EDeleteRepeat = EDeleteRepeat.TODAY

  @Watch('updateView')
  changeView() {
    if (this.updateView) {
      let changeViewCalendar: any = this.updateView
      this.$router.push({
        name: 'calendar',
        params: { changeViewCalendar }
      })
    }
  }

  async created() {
    this.current_user_id = this.$store.state.userInfo.user.info.id
    await this.getEventCalendarById()
    this.markReadCalendarEvent()
  }

  async getEventCalendarById() {
    return CalendarService.getCalendarEventById(this.$route.params.id)
      .then(response => {
        if (response.status === 200) {
          this.eventPreview = response.data

          // //hanlde end date for DURRATION
          // if (response.data.plan_type === EPlanType.DURRATION) {
          //   this.eventPreview.event_end_date = moment(
          //     this.eventPreview.event_end_date
          //   )
          //     .subtract(1, 'minute')
          //     .format('YYYY/MM/DD HH:mm')
          // }

          this.eventPreview.event_start_date = moment(
            this.eventPreview.event_start_date
          ).format('YYYY/MM/DD (dd) HH:mm')
          this.eventPreview.event_end_date = moment(
            this.eventPreview.event_end_date
          ).format('YYYY/MM/DD (dd) HH:mm')

          this.checkTitleDisplay()
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.errorMess = this.$t('calendar.create.event_not_exist') as string
          this.modalAction = () => this.goToCalendar()
          this.$bvModal.show('modal-error-event-preview')
        }
        if (error.response.status === 403) {
          this.errorMess = this.$t(
            'common.message.access_permission_denied'
          ) as string
          this.modalAction = () => this.goToCalendar()
          this.$bvModal.show('modal-error-event-preview')
        }
      })
  }

  hanldeUserStatus(name: string, status: EUserStatus) {
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + this.$t('common.suffix.leave_group')
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t('common.suffix.leave_system') as string
    return name
  }

  /**
   * Mark read
   */
  markReadCalendarEvent() {
    let eventIds = { eventIds: [this.$route.params.id] }
    CalendarService.markEventCalendarReaded(eventIds)
  }

  goToEditEventRoute() {
    return {
      name: 'calendar-edit'
    }
  }

  goToCalendar() {
    this.$router.push({ name: 'calendar' })
  }

  deleteEvent(deleteType?: EDeleteRepeat) {
    this.$bvModal.hide('modal-confirm-delete-repeat')

    const eventId = this.$route.params.id
    const delType = deleteType ? deleteType : EDeleteRepeat.ALL
    let chooseDate = moment().format('YYYY/MM/DD')

    if (moment(String(this.$route.query.chooseDate)).isValid()) {
      chooseDate = String(this.$route.query.chooseDate)
    }

    CalendarService.deleteCalendarEventById(eventId, delType, chooseDate)
      .then(response => {
        if (response.status === 200) {
          this.$bvModal.show('modal-success')
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          this.openErrorModal(
            this.$t('common.message.delete_permission_denied').toString()
          )
        } else {
          this.openErrorModal(this.$t('common.message.delete_fail').toString())
        }
      })
  }

  openErrorModal(mess: string) {
    this.errorMess = mess
    this.$bvModal.show('modal-error')
  }

  handleFavor() {
    CalendarService.markEventCalendarFavor(this.eventPreview.id)
      .then(response => {
        if (response.status === 200) {
          this.eventPreview.is_favorite = response.data.is_favorite
          triggerFavorite()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  checkTitleDisplay() {
    if (this.eventPreview.plan_type === EPlanType.NORMAL) {
      this.display_title = this.$t('calendar.preview.regular_event').toString()
    } else if (this.eventPreview.plan_type === EPlanType.DURRATION) {
      this.display_title = this.$t('calendar.preview.period_event').toString()
    } else {
      this.display_title = this.$t('calendar.preview.repeat_event').toString()
    }
  }

  openModalConfirmDelete() {
    if (this.eventPreview.plan_type === EPlanType.REPEAT) {
      this.$bvModal.show('modal-confirm-delete-repeat')
    } else {
      this.$bvModal.show('modal-confirm')
    }
  }

  handleRepeatTime(event: EventPreviewVM) {
    const today = moment(String(this.$route.query.chooseDate)).format(
      'YYYY/MM/DD (dd)'
    )
    const start = moment(this.eventPreview.event_start_date).format('HH:mm')
    const end = moment(this.eventPreview.event_end_date).format('HH:mm')
    return `${today} ${start} 〜${today} ${end}`
  }

  handleRepeatDetail(event: EventPreviewVM) {
    const startTime = this.moment(event.event_start_date).format('HH:mm')
    const endTime = this.moment(event.event_end_date).format('HH:mm')
    const deadline =
      event.deadline_type === EDeadLineType.TIME_LIMIT
        ? this.moment(event.end_deadline).format('YYYY/MM/DD') +
          this.$t('calendar.create.util')
        : this.$t('calendar.create.indefinite')

    let repeat = ''
    switch (event.repeat_type) {
      case ERepeatType.DAILY:
        repeat = this.$t('calendar.create.condition_repeat.every_day') as string
        break
      case ERepeatType.DAILY_NO_WEEKEND:
        repeat = this.$t(
          'calendar.create.condition_repeat.every_day_without_weekend'
        ) as string
        break
      case ERepeatType.WEEKLY:
        repeat = this.$t(
          'calendar.create.condition_repeat.every_week_day'
        ) as string
        break
      case ERepeatType.MONTHLY:
        repeat = this.$t(
          'calendar.create.condition_repeat.every_month_day'
        ) as string
        break
      default:
        break
    }

    const conditon = `${this.$t('calendar.preview.condiction')}：${repeat}`
    const deadlineText = `${this.$t('calendar.preview.deadline')}：${deadline}`

    return `${conditon}　${deadlineText}　${startTime} 〜 ${endTime}`
  }

  handleLinkifyContent(content: string) {
    return linkifyHtml(content || '', { target: "_blank" });
  }
}

class EventPreviewVM {
  id!: number
  plan_type: EPlanType = EPlanType.NORMAL
  repeat_type: ERepeatType = ERepeatType.DAILY
  every_week_day: any
  every_month_day: any
  deadline_type: EDeadLineType = EDeadLineType.INDEFINITE
  end_deadline: string = ''
  event_start_date: string = ''
  event_end_date: string = ''
  title: string = ''
  memo: string = ''
  event_color: string = ''
  created_by!: number
  list_group_choose: IdName[] = []
  list_member_choose: any[] = []
  accept_member_change_plan: boolean = false
  list_setting_device: any[] = []
  share_type: EShareType = EShareType.GROUP_TAG
  updated_by!: number
  is_favorite: boolean = false
  user_favorite: any[] = []
  user_creator: IdNameStatus = new IdNameStatus()
  user_updater: IdNameStatus = new IdNameStatus()

  constructor(init?: Partial<EventPreviewVM>) {
    Object.assign(this, init)
  }
}

enum EDeleteRepeat {
  TODAY = 1,
  FUTURE = 2,
  ALL = 3
}
